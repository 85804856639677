
.headerContent h3 {
  font-size: 40px;
  color: white;
}
p.fs-5.mb-0 {
  color: #702747 !important;
  font-size: 17px !important;
  font-weight: 500;
}

.animate-character {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #d37d89 0%,
    #702747 29%,
    #ae3b8b 67%,
    #ffffff 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
  font-size: 40px;
}
p.fs-5.mb-0.text-secondary {
  color: white !important;
  font-size: 17px !important;
}
.headerContent p {
  font-size: 18px;
  width: 85%;
  color: #fff;
  text-align: justify;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.headerContent {
  height: 50vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-color: var(--bg);
  padding-left: 0px;
}

@keyframes showBars {
  0% {
    opacity: 0;
    background-position: -400% 7%, 500% 21%, -400% 35%, 500% 49%, -400% 63%,
      500% 77%, -400% 91%;
  }
  14% {
    background-position: 0% 7%, 500% 21%, -400% 35%, 500% 49%, -400% 63%,
      500% 77%, -400% 91%;
  }
  28% {
    background-position: 0% 7%, 100% 21%, -400% 35%, 500% 49%, -400% 63%,
      500% 77%, -400% 91%;
  }
  42% {
    background-position: 0% 7%, 100% 21%, 0% 35%, 500% 49%, -400% 63%, 500% 77%,
      -400% 91%;
  }
  56% {
    background-position: 0% 7%, 100% 21%, 0% 35%, 100% 49%, -400% 63%, 500% 77%,
      -400% 91%;
  }
  70% {
    background-position: 0% 7%, 100% 21%, 0% 35%, 100% 49%, 0% 63%, 500% 77%,
      -400% 91%;
  }
  84% {
    background-position: 0% 7%, 100% 21%, 0% 35%, 100% 49%, 0% 63%, 100% 77%,
      -400% 91%;
  }
  98%,
  100% {
    opacity: 1;
    background-position: 0% 7%, 100% 21%, 0% 35%, 100% 49%, 0% 63%, 100% 77%,
      0% 91%;
  }
}

.headerContent::after {
  content: "";
  width: 100%;
  height: 20vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: inherit;
  background-repeat: no-repeat;
  background-size: 70% 7%;
  background-image: linear-gradient(var(--c1), var(--c1)),
    linear-gradient(var(--c2), var(--c2)), linear-gradient(var(--c1), var(--c1)),
    linear-gradient(var(--c2), var(--c2)), linear-gradient(var(--c1), var(--c1)),
    linear-gradient(var(--c2), var(--c2)), linear-gradient(var(--c1), var(--c1));
  background-position: 0% 7%, 100% 21%, 0% 35%, 100% 49%, 0% 63%, 100% 77%,
    0% 91%;
  animation: showBars 3.5s;
}

@keyframes showText {
  0% {
    opacity: 0;
    transform: translate(0, -100%);
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.headerContent > div {
  position: relative;
  transform: translate(-100%, 0);
  opacity: 0;
  animation: showText 2s 1;
  animation-fill-mode: forwards;
  animation-delay: 2.5s;
  text-align: center;
}

@media all and (min-width: 768px) {
  @keyframes showBarsBig {
    0% {
      opacity: 0;
      background-position: 7% -400%, 21% 500%, 35% -400%, 49% 500%, 63% -400%,
        77% 500%, 91% -400%;
    }
    14% {
      background-position: 7% 0%, 21% 500%, 35% -400%, 49% 500%, 63% -400%,
        77% 500%, 91% -400%;
    }
    28% {
      background-position: 7% 0%, 21% 100%, 35% -400%, 49% 500%, 63% -400%,
        77% 500%, 91% -400%;
    }
    42% {
      background-position: 7% 0%, 21% 100%, 35% 0%, 49% 500%, 63% -400%,
        77% 500%, 91% -400%;
    }
    56% {
      background-position: 7% 0%, 21% 100%, 35% 0%, 49% 100%, 63% -400%,
        77% 500%, 91% -400%;
    }
    70% {
      background-position: 7% 0%, 21% 100%, 35% 0%, 49% 100%, 63% 0%, 77% 500%,
        91% -400%;
    }
    84% {
      background-position: 7% 0%, 21% 100%, 35% 0%, 49% 100%, 63% 0%, 77% 100%,
        91% -400%;
    }
    98%,
    100% {
      opacity: 1;
      background-position: 7% 0%, 21% 100%, 35% 0%, 49% 100%, 63% 0%, 77% 100%,
        91% 0%;
    }
  }

  @keyframes showTextBig {
    0% {
      opacity: 0;
      transform: translate(-100%, 0);
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translate(0vw, 0);
    }
  }

  .headerContent {
    height: 60vh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .headerContent::after {
    width: 20vw;
    height: 100%;
    background-size: 7% 70%;
    background-position: 7% 0%, 21% 100%, 35% 0%, 49% 100%, 63% 0%, 77% 100%,
      91% 0%;
    animation-name: showBarsBig;
  }

  .headerContent > div {
    animation-name: showTextBig;
    margin-left: 22vw;
    text-align: left;
  }

  h1 {
    font-size: 8vw;
  }

  p {
    font-size: 4vw;
    margin-bottom: 0;
  }
}

@media (prefers-reduced-motion) {
  .headerContent::after {
    animation: none !important;
  }

  @keyframes showTextReduced {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .headerContent > div {
    transform: translate(0, 0);
    animation-name: showTextReduced;
    animation-delay: 0.5s !important;
  }
}
:root {
  --bg: #1c5789;
  --c1: #7a2048;
  --c2: #fff;
  --c3: #a8dadc;
}

.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.container.reveal.fade-left.active,
.container.reveal.fade-right.active,
.container.reveal.active {
  width: 100%;
  margin: 0;
  padding: 0;
}
.container.reveal.fade-bottom.active {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
  justify-content: center;
}

.theme-text {
  color: #474af0;
}

.card-img-top {
  height: 200px;
  width: 100%;
}
.card {
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}

.card::before {
  content: "";
  position: absolute;
  left: -100%;
  top: 0%;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: -1;
}
.card:hover::before {
  left: 0%;
  color: white;
}
.card-body h3 {
  margin-bottom: 18px;
}
button.cardbtn:hover {
  cursor: pointer;
  background: #2d5685;
}

.cardbtn {
  margin-bottom: 10px;
  color: white;
  background: #702747;
  border: none;
  width: 100%;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
}
h3.serviceBox {
  text-align: center;
  padding-top: 20px 0px 20px 0px;
  padding: 20px 0px;
  font-size: 44px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  color: #702747;
  margin-bottom: 24px;
}
.servicePara {
  font-size: 22px;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
  color: black;
  line-height: 28px;
}
.successBox1 {
  color: white;
  background: #2d5685;
}
.successBox2 {
  color: white;
  background: #702747;
}
.successBox3 {
  background: #e17888;
  color: white;
}
.successBox4 {
  background: #ae3b8b;
  color: white;
}
.successBox5 {
  background: #26c6da;
  color: white;
}
.circle {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small {
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium {
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large {
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge {
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge {
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1 {
  opacity: 0.2;
}
.shade2 {
  opacity: 0.5;
}

.shade3 {
  opacity: 0.7;
}

.shade4 {
  opacity: 0.8;
}

.shade5 {
  opacity: 0.9;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}
.card.shadow:hover {
  background: transparent !important;
}


.card {
  border: none;
}
.product {
  background-color: #fff6fa;;
}
button.btn.btn-danger.text-uppercase.mr-2.px-4 {
    background: #48526c !important;
}
.brand {
  font-size: 13px;
}
.act-price {
  color: red;
  font-weight: 700;
}
.dis-price {
  text-decoration: line-through;
}
.about {
  font-size: 14px;
}
.color {
  margin-bottom: 10px;
}
label.radio {
  cursor: pointer;
}
label.radio input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
}
label.radio span {
  padding: 2px 9px;
  display: inline-block;
  color: #ff0000;
  border-radius: 3px;
  text-transform: uppercase;
}
label.radio input:checked + span {
  border-color: #ff0000;
  background-color: #ff0000;
  color: #fff;
}
.btn-danger {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}
.btn-danger:hover {
  background-color: #da0606 !important;
  border-color: #da0606 !important;
}
.btn-danger:focus {
  box-shadow: none;
}
.cart i {
  margin-right: 10px;
}
